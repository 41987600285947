import React, { useState, useEffect } from 'react';
import Text from '../Text';
import { Typography } from '@mui/material';
import {
    SimpleStrideData,
    createStrideLink,
    PathData,
    SimplePathData,
    createPathLink,
    StrideData,
    StrideDataInput
} from '../../Data';
import { LinkData } from '../../Navigation';
import Tags from '../Tags';
import Link from '../Link';
import { StrideList } from './Stride';
import PathList from './PathList';

export type PathInputs = {
    data: PathData,
}

export default function Path({ data }: PathInputs) {
    const { title, summary, tags, strides, similarPaths } = data;
    function getSimilarPathLinks(paths: SimplePathData[] | undefined): LinkData[] {
        return paths?.map((path) => createPathLink({ path })) ?? [];
    }

    const [similarPathLinks, setSimilarPathLinks] = useState<LinkData[]>(() => getSimilarPathLinks(similarPaths));

    useEffect(() => {
        setSimilarPathLinks(getSimilarPathLinks(similarPaths));
    }, [similarPaths]);

    return (
        <React.Fragment>
            <Typography variant="h3" component="h1">
                {title}
            </Typography>
            <Tags tags={tags} />
            <Typography variant="body1" component="div">
                <Text textData={summary} />
            </Typography>
            <StrideList strides={strides} path={data} showNumbers={true} />
            <PathList title="Similar Paths:" paths={similarPaths} hideOnEmpty />
        </React.Fragment >
    );
}