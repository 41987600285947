import React from 'react';
import { createPathLink, PathData, SimplePathData } from '../../Data';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { ListItemLink } from '../Link';
import Typography from '@mui/material/Typography';

export type PathListInputs = {
    paths: PathData[] | SimplePathData[];
    title: string;
    hideOnEmpty?: boolean | undefined;
}

export default function PathList({ paths, title, hideOnEmpty = false }: PathListInputs) {
    const pathLinks = paths.map((path) => createPathLink({ path }));
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const handleToggle = (index: number) => () => {
        const newCompleted = completed;
        newCompleted[index] = true;
        setCompleted(newCompleted);
    };

    if (hideOnEmpty && paths.length == 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Typography variant="h4" component="h1">
                {title}
            </Typography>
            {paths.length > 0 && (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {paths.map((path, index) => {
                        const labelId = `checkbox-list-label-${path.id}`;
                        const link = pathLinks[index];

                        return (
                            <ListItem
                                key={path.id}
                                secondaryAction={
                                    <Checkbox
                                        onClick={handleToggle(index)}
                                        edge="start"
                                        checked={completed[index]}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemLink data={link} labelId={labelId} shortDescription={path.shortDescription} />
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {paths.length == 0 && (
                <Typography>
                    No paths found.
                </Typography>
            )}
        </React.Fragment>
    );
}