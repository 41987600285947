import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { MobileApp, BrowserApp } from './Layout';
import { UserProvider } from './Authentication/UserContext';
import { DataProvider } from './Components';
import styles from './App.module.scss';

import './App.scss';


export default function App() {
  return (
    <UserProvider>
      <DataProvider>
        <React.Fragment>
          <BrowserView className={styles.app}>
            <BrowserApp />
          </BrowserView>
          <MobileView className={styles.app}>
            <MobileApp />
          </MobileView>
        </React.Fragment>
      </DataProvider>
    </UserProvider>
  );
}
