import * as React from 'react';
import Typography from '@mui/material/Typography';
import { TextData } from '../../Data';
import { LinkData, isLinkData } from '../../Navigation';
import Link from '../Link';

type TextInputs = {
    textData: TextData,
    variant?: "body1" | "button" | "caption" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body2" | "overline" | "inherit" | undefined,
    component?: React.ElementType,
}

export default function Text({ textData, variant = "body1", component = "p" }: TextInputs) {
    return (
        <React.Fragment>
            {(textData).map((textOrLinks, indexP) => (
                <Typography key={`p:${indexP}`} variant={variant} component={component}>
                    {(textOrLinks).map((textOrLink, indexSpan) => {
                        if (isLinkData(textOrLink)) {
                            return (<Link key={`span:${indexP}.${indexSpan}`} {...(textOrLink as LinkData)} />);
                        }
                        return (textOrLink as string);
                    })}
                </Typography>
            ))}
        </React.Fragment>
    );
}