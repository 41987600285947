import React, { useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Login from '../Login';
import { profileSettingsLink } from '../../Navigation/Routes/LinkData';
import { useUserActionsContext, useUserContext } from '../UserContext';
import { useSearchParams } from "react-router-dom";

export default function MyAvatar() {
    let [searchParams, setSearchParams] = useSearchParams();
    const userContext = useUserContext();
    const userActions = useUserActionsContext();
    const navigate = useNavigate();

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(menuAnchorEl);
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setMenuAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const [loginAnchorEl, setLoginAnchorEl] = React.useState<null | HTMLElement>(() => {
        const isLogin = searchParams.get('login') != null;
        if (isLogin) {
            return document.body;
        }
        return null;
    });
    const loginOpen = Boolean(loginAnchorEl);


    const handleLoginClick = () => (event: React.MouseEvent<HTMLElement>) => {
        setSearchParams({
            ['login']: ''
        });
        setLoginAnchorEl(event.currentTarget);
    };
    const handleLoginClose = () => {
        setSearchParams({});
        setLoginAnchorEl(null);
    };

    const handleLogout = () => {
        userActions.logout();
    }

    useEffect(() => {
        const isLogin = searchParams.get('login') != null;
        if (isLogin && loginAnchorEl == null) {
            setLoginAnchorEl(document.body);
        }
        if (!isLogin && loginAnchorEl != null) {
            setLoginAnchorEl(null);
        }
    }, [searchParams]);

    const List = () => (
        <Menu
            anchorEl={menuAnchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            onClick={handleMenuClose}
            PaperProps={{
                elevation: 0,
                sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                    },
                    '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                    },
                },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
            <MenuList>
                <MenuItem>
                    <ListItemButton onClick={() => navigate(profileSettingsLink.to)}>
                        <ListItemIcon>
                            <SettingsIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Profile Settings'} />
                    </ListItemButton>
                </MenuItem>
                <MenuItem>
                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon>
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Logout'} />
                    </ListItemButton>
                </MenuItem>
            </MenuList>
        </Menu>
    );

    return (
        <React.Fragment>
            {userContext.currentUser != null &&
                <React.Fragment>
                    <IconButton sx={{ p: 0 }} onClick={handleMenuClick}>
                        <Avatar src={userContext.currentUser?.photoURL ?? undefined} />
                    </IconButton>
                    <List />

                </React.Fragment>
            }

            {userContext.currentUser == null &&
                <React.Fragment>
                    <Button onClick={handleLoginClick()}>
                        Sign In
                    </Button>
                </React.Fragment>
            }


            <Login onClose={handleLoginClose} open={loginOpen}></Login>
        </React.Fragment>
    )
}