import React, { useState, useEffect, ReactNode } from 'react';
import Typography from '@mui/material/Typography';
import { useDataContext } from '../DataContext';
import Data, { PathData } from '../../Data';
import { useParams } from "react-router-dom";

export type DataLoadingInputs = {
    children?: ReactNode | undefined,
    title?: string | undefined,
};

export default function DataLoading({
    children = undefined,
    title = undefined,
}: DataLoadingInputs) {
    const { data, isLoading } = useDataContext();
    const { pathId } = useParams();

    function getPath(pathId: string | undefined, isLoading: boolean, data: Data | undefined): PathData | undefined {
        if (isLoading || data == undefined || pathId == undefined) {
            return undefined;
        } else {
            return new PathData({
                data: data.paths.get(pathId)!,
                strides: data.strides,
                paths: data.paths,
            })
        }
    }

    const [path, setPath] = useState<PathData | undefined>(() => getPath(pathId, isLoading, data));

    useEffect(() => {
        setPath(getPath(pathId, isLoading, data));
    }, [pathId, data, isLoading]);

    return (
        <React.Fragment>
            {title != undefined && (
                <Typography variant="h4" component="h1" align="center" gutterBottom>
                    {title}
                </Typography>
            )}
            {isLoading && (
                <React.Fragment>
                    <Typography variant="body1" component="div">
                        Loading...
                    </Typography>
                </React.Fragment>
            )}
            {!isLoading && data != undefined && (
                children
            )}
            {!isLoading && data == undefined && (
                <Typography variant="body1" component="div">
                    There was an error loading data.
                </Typography>
            )}
        </React.Fragment>
    );
}