import LinkData from './LinkData';
import PageData, {
    homePage,
    profileSettingsPage,
    contactUsPage,
    currentCoursePage,
    learningPathsPage,
    schedulePage,
    supportUsPage,
    videosPage,
} from '../PageData';

// *********************** Internal Links ************************** //

function getInternalLinkDataFromPageData(pageData: PageData): LinkData {
    return {
        ariaLabel: pageData.description,
        text: pageData.title,
        to: pageData.path
    } as LinkData;
}

const homeLink: LinkData = getInternalLinkDataFromPageData(homePage);
const profileSettingsLink: LinkData = getInternalLinkDataFromPageData(profileSettingsPage);
const contactUsLink: LinkData = getInternalLinkDataFromPageData(contactUsPage);
const currentCourseLink: LinkData = getInternalLinkDataFromPageData(currentCoursePage);
const learningPathsLink: LinkData = getInternalLinkDataFromPageData(learningPathsPage);
const scheduleLink: LinkData = getInternalLinkDataFromPageData(schedulePage);
const supportUsLink: LinkData = getInternalLinkDataFromPageData(supportUsPage);
const videosLink: LinkData = getInternalLinkDataFromPageData(videosPage);

// ************************* Link Lists **************************** //

const allInternalLinks: Array<LinkData> = [homeLink];
const homePageLinks: Array<LinkData> = [];

export default allInternalLinks;
export {
    // *********************** Internal Links ************************** //
    homeLink,
    profileSettingsLink,
    contactUsLink,
    currentCourseLink,
    learningPathsLink,
    scheduleLink,
    supportUsLink,
    videosLink,
    getInternalLinkDataFromPageData,

    // ************************* Link Lists **************************** //
    homePageLinks,
};