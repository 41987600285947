import * as React from 'react';
import { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import { Avatar } from '../../Authentication';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import HomeIcon from '@mui/icons-material/Home';
import ScheduleIcon from '@mui/icons-material/CalendarMonth';
import VideosIcon from '@mui/icons-material/SmartDisplay';
import LearningIcon from '@mui/icons-material/Route';
import ClassIcon from '@mui/icons-material/School';
import SupportIcon from '@mui/icons-material/Support';
import ContactIcon from '@mui/icons-material/Mail';
import {
    // *********************** Internal Links ************************** //
    homeLink,
    contactUsLink,
    currentCourseLink,
    learningPathsLink,
    scheduleLink,
    supportUsLink,
    videosLink,
} from '../Routes';

type AppBarAnchor = 'top' | 'bottom';

type MyAppBarInputs = {
    readonly anchor?: AppBarAnchor,
    children?: ReactNode
}

const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

export default function MyAppBar({ anchor = 'top', children }: MyAppBarInputs) {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = React.useState(false);

    const toggleDrawer =
        (open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setIsOpen(open);
            };

    const isTop = anchor == 'top';

    const list = () => (
        <Box
            sx={{ width: isTop ? 250 : 'auto' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(homeLink.to)}>
                        <ListItemIcon>
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Home'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(scheduleLink.to)}>
                        <ListItemIcon>
                            <ScheduleIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Schedule'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(videosLink.to)}>
                        <ListItemIcon>
                            <VideosIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Videos'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(learningPathsLink.to)}>
                        <ListItemIcon>
                            <LearningIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Learning Paths'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(currentCourseLink.to)}>
                        <ListItemIcon>
                            <ClassIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Current Course'} />
                    </ListItemButton>
                </ListItem>
            </List>
            <Divider />
            <List>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(supportUsLink.to)}>
                        <ListItemIcon>
                            <SupportIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Support Us'} />
                    </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(contactUsLink.to)}>
                        <ListItemIcon>
                            <ContactIcon />
                        </ListItemIcon>
                        <ListItemText primary={'Contact Us'} />
                    </ListItemButton>
                </ListItem>
            </List>
            {/* <List>
                {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <Divider />
            <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem key={text} disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                            </ListItemIcon>
                            <ListItemText primary={text} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List> */}
        </Box>
    );

    return (
        <React.Fragment>
            <AppBar position="fixed" color="primary" sx={isTop ? { top: 0, bottom: 'auto' } : { top: 'auto', bottom: 0 }}>
                <Toolbar>
                    <IconButton
                        onClick={toggleDrawer(true)}
                        color="inherit"
                        aria-label="open drawer">
                        <MenuIcon />
                    </IconButton>
                    {children}

                    <Box sx={{ flexGrow: 1 }}>
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Avatar />
                    </Box>
                </Toolbar>
            </AppBar>
            <Offset />

            <Drawer
                anchor={isTop ? 'left' : 'bottom'}
                open={isOpen}
                onClose={toggleDrawer(false)}
            >
                {list()}
            </Drawer>
        </React.Fragment >
    );
}