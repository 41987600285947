import * as React from 'react';
import Typography from '@mui/material/Typography';

export default function Schedule() {
    return (
        <React.Fragment>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Schedule
                {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, quas tempora magni minima et commodi quia ipsum, cumque quo illo ea. Dicta perferendis dolor recusandae totam quas, voluptates adipisci eos! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi dicta totam impedit ratione saepe qui voluptatem distinctio. Laborum illo nihil voluptate at quaerat ducimus, aliquid numquam, blanditiis nisi, sed pariatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus accusamus doloribus voluptas repudiandae est, beatae amet at consectetur, aperiam ipsa nisi sed cumque enim doloremque maxime quae voluptates inventore illo? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, obcaecati, asperiores in ullam nostrum placeat atque deserunt, quisquam repellat est voluptatem suscipit assumenda dolores sapiente fuga expedita. Ducimus, asperiores quaerat! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, exercitationem illum, sapiente iure quo eius repudiandae tempore itaque rerum corrupti mollitia quasi eveniet aliquam ducimus, dolores dolorum quia deserunt est. */}
            </Typography>
            <Typography variant="body1" component="div">
                This is a placeholder for our schedule page.
                {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, quas tempora magni minima et commodi quia ipsum, cumque quo illo ea. Dicta perferendis dolor recusandae totam quas, voluptates adipisci eos! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi dicta totam impedit ratione saepe qui voluptatem distinctio. Laborum illo nihil voluptate at quaerat ducimus, aliquid numquam, blanditiis nisi, sed pariatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus accusamus doloribus voluptas repudiandae est, beatae amet at consectetur, aperiam ipsa nisi sed cumque enim doloremque maxime quae voluptates inventore illo? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, obcaecati, asperiores in ullam nostrum placeat atque deserunt, quisquam repellat est voluptatem suscipit assumenda dolores sapiente fuga expedita. Ducimus, asperiores quaerat! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, exercitationem illum, sapiente iure quo eius repudiandae tempore itaque rerum corrupti mollitia quasi eveniet aliquam ducimus, dolores dolorum quia deserunt est. */}
            </Typography>
        </React.Fragment>
    );
}