import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { getRemoteConfig } from "firebase/remote-config";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCHBdjLLvnOgQ7s0AfsDc75xf16Mj1GksQ",
    authDomain: "taylorthe.tech",
    projectId: "taylor-the-tech-tutor",
    storageBucket: "taylor-the-tech-tutor.appspot.com",
    messagingSenderId: "362518719518",
    appId: "1:362518719518:web:a50528d34867b046c9120f",
    measurementId: "G-PP9YBCM3NZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// firebaseApps previously initialized using initializeApp()
const storage = getStorage();
const auth = getAuth(app);

const remoteConfig = getRemoteConfig(app);

remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
    connectAuthEmulator(auth, "http://localhost:9099");
    connectStorageEmulator(storage, 'localhost', 9199);
    remoteConfig.defaultConfig = {
        "data": "/site/data.01.01.json"
    };
}

setPersistence(auth, browserSessionPersistence);



export default {
    app,
    storage,
    auth,
    remoteConfig,
}