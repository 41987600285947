import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import { routes } from './App'

const rootElement = document.getElementById('root');

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes
  }
]);

const root = ReactDOM.createRoot(rootElement!);

root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <RouterProvider router={router} />
  </ThemeProvider>,
);
