import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

export type TagsInputs = {
    tags: string[],
}

export default function Tags({ tags }: TagsInputs) {
    const handleClick = (index: number) => () => {
        console.log("Clicked tag " + tags[index]);
    }

    return (
        <Stack direction="row" spacing={1}>
            {tags.map((tag, index) => (
                <Chip variant='outlined' key={tag} label={tag} onClick={handleClick(index)} />
            ))}
        </Stack >
    );
}