import * as React from 'react';
import Typography from '@mui/material/Typography';
import YouTube, { YouTubeProps } from 'react-youtube';
import { EmbeddedVideo } from '../../Data';
import Text from '../Text';

export type VideoInputs = {
    data: EmbeddedVideo,
    isAutoPlay?: boolean,
    shouldDisplayText?: boolean
};

export default function Video({ data: { youTubeId, name, description, start, end }, isAutoPlay = false, shouldDisplayText = false }: VideoInputs) {
    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
        console.log(event);
    }
    const onPlayerEnd: YouTubeProps['onEnd'] = (event) => {
        // access to player in all event handlers via event.target
        console.log(event);
    }
    const onPlayerStateChange: YouTubeProps['onStateChange'] = (event) => {
        // access to player in all event handlers via event.target
        console.log(event);
    }

    const opts: YouTubeProps['opts'] = {
        height: '390',
        width: '640',
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: isAutoPlay ? 1 : 0,
            start,
            end
        },
    };

    return (
        <React.Fragment>
            {shouldDisplayText && (
                <React.Fragment>
                    <Typography variant="h4" component="h1" align="center" gutterBottom>
                        {name}
                    </Typography>
                    <Text textData={description} variant="body1" component="div" />
                </React.Fragment>
            )}
            <YouTube videoId={youTubeId} opts={opts} onReady={onPlayerReady} onEnd={onPlayerEnd} onStateChange={onPlayerStateChange} />
        </React.Fragment>
    );
}