import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useDataContext, DataLoading, Path } from '../../Components';
import Data, { PathData } from '../../Data';
import { useParams } from "react-router-dom";

export default function PathHome() {
    const { data, isLoading } = useDataContext();
    const { pathId } = useParams();

    function getPath(pathId: string | undefined, isLoading: boolean, data: Data | undefined): PathData | undefined {
        if (isLoading || data == undefined || pathId == undefined) {
            return undefined;
        } else {
            return new PathData({
                data: data.paths.get(pathId)!,
                strides: data.strides,
                paths: data.paths,
            })
        }
    }

    const [path, setPath] = useState<PathData | undefined>(() => getPath(pathId, isLoading, data));

    const [pathTitle, setPathTitle] = useState<string | undefined>(() => isLoading ? 'Learning Path' : undefined);

    useEffect(() => {
        setPath(getPath(pathId, isLoading, data));
        setPathTitle(isLoading ? 'Learning Path' : undefined);
    }, [pathId, data, isLoading]);

    return (
        <React.Fragment>
            <DataLoading title={pathTitle}>
                {path != undefined && (
                    <Path data={path!} />
                )}
                {path == undefined && (
                    <Typography variant="body1" component="div">
                        There was an error loading data.
                    </Typography>
                )}
            </DataLoading>
        </React.Fragment>
    );
}