import { type TextData } from '../../TextData';
import { SimpleVideoData, SimpleClipData, ClipData } from '../../VideoData';
import ImageData from '../../ImageData';

export type StepDataInput = {
    readonly data: SimpleStepData,
    readonly videos: Map<string, SimpleVideoData>,
    readonly clips: Map<string, SimpleClipData>,
    readonly images: Map<string, ImageData>,
}

export type SimpleStepData = {
    readonly id: string,
    readonly title: string,
    readonly text: TextData,
    readonly imageId?: string | undefined,
    readonly videoId?: string | undefined,
    readonly clipId?: string | undefined,
}

export default class StepData {
    readonly id: string;
    readonly title: string;
    readonly text: TextData;
    readonly imageId?: string | undefined;
    readonly videoId?: string | undefined;
    readonly clipId?: string | undefined;
    readonly image?: ImageData | undefined;
    readonly video?: SimpleVideoData | undefined;
    readonly clip?: ClipData | undefined;

    constructor({
        data: {
            id,
            imageId,
            videoId,
            title,
            text,
        },
        images,
        videos,
        clips,
    }: StepDataInput) {
        this.id = id;
        this.imageId = imageId;
        this.videoId = videoId;
        this.title = title;
        this.text = text;
        if (this.imageId != undefined) {
            this.image = images.get(this.imageId)!;
        }
        if (this.videoId != undefined) {
            this.video = videos.get(this.videoId)!;
        }
        if (this.clipId != undefined) {
            this.clip = new ClipData({ data: clips.get(this.clipId)!, videos });
        }
    }
}