import * as React from 'react';
import { ReactNode } from 'react';
import Container from '@mui/material/Container';

export type CenteredContainerInputs = {
    children?: ReactNode,
    className?: string,
}

export default function CenteredContainer({ children, className }: CenteredContainerInputs) {
    return (
        <Container className={className} maxWidth="sm">
            {children}
        </Container>
    );
}
