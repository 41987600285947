import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import { redirect, useNavigate } from "react-router-dom";
import firebase from '../../Authentication/firebase';
import {
    User,
} from "firebase/auth";
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useUserContext, SupportedProvider, supportedProviders, useUserActionsContext } from '../../Authentication/UserContext';
import 'firebaseui/dist/firebaseui.css';

export const profileSettingsRedirectLoader = async (): Promise<any> => {
    const jsonUser = sessionStorage.getItem('user');
    const user = firebase.auth.currentUser ?? jsonUser != null ? (JSON.parse(jsonUser!) as User | null) : null;
    if (user == null) {
        return redirect("/tutor");
    }
    return null;
};

export default function ProfileSettings() {
    const userContext = useUserContext();
    const userActions = useUserActionsContext();
    const navigate = useNavigate();

    const handleLink = (authProvider: SupportedProvider) => (event: React.MouseEvent<HTMLElement>) => {
        userActions.link(authProvider);
    };

    useEffect(() => {
        if (userContext.currentUser == null) {
            navigate('/tutor');
        }
    }, [userContext]);

    return (
        <React.Fragment>
            <Typography variant="h4" component="h1" align="center" gutterBottom>
                Profile Settings
                {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, quas tempora magni minima et commodi quia ipsum, cumque quo illo ea. Dicta perferendis dolor recusandae totam quas, voluptates adipisci eos! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi dicta totam impedit ratione saepe qui voluptatem distinctio. Laborum illo nihil voluptate at quaerat ducimus, aliquid numquam, blanditiis nisi, sed pariatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus accusamus doloribus voluptas repudiandae est, beatae amet at consectetur, aperiam ipsa nisi sed cumque enim doloremque maxime quae voluptates inventore illo? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, obcaecati, asperiores in ullam nostrum placeat atque deserunt, quisquam repellat est voluptatem suscipit assumenda dolores sapiente fuga expedita. Ducimus, asperiores quaerat! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, exercitationem illum, sapiente iure quo eius repudiandae tempore itaque rerum corrupti mollitia quasi eveniet aliquam ducimus, dolores dolorum quia deserunt est. */}
            </Typography>
            {userContext.currentUser != null &&
                <div>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Currently selected:
                        {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, quas tempora magni minima et commodi quia ipsum, cumque quo illo ea. Dicta perferendis dolor recusandae totam quas, voluptates adipisci eos! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi dicta totam impedit ratione saepe qui voluptatem distinctio. Laborum illo nihil voluptate at quaerat ducimus, aliquid numquam, blanditiis nisi, sed pariatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus accusamus doloribus voluptas repudiandae est, beatae amet at consectetur, aperiam ipsa nisi sed cumque enim doloremque maxime quae voluptates inventore illo? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, obcaecati, asperiores in ullam nostrum placeat atque deserunt, quisquam repellat est voluptatem suscipit assumenda dolores sapiente fuga expedita. Ducimus, asperiores quaerat! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, exercitationem illum, sapiente iure quo eius repudiandae tempore itaque rerum corrupti mollitia quasi eveniet aliquam ducimus, dolores dolorum quia deserunt est. */}
                    </Typography>
                    <IconButton sx={{ p: 0 }}>
                        <Avatar src={userContext.currentUser?.photoURL ?? undefined} />
                    </IconButton>
                </div>
            }
            {supportedProviders.map(({ name, authProvider }) => (
                <div key={authProvider.providerId}>
                    {userContext.providerLookup.has(authProvider.providerId ?? '') && (
                        <React.Fragment>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {name}:
                                {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, quas tempora magni minima et commodi quia ipsum, cumque quo illo ea. Dicta perferendis dolor recusandae totam quas, voluptates adipisci eos! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi dicta totam impedit ratione saepe qui voluptatem distinctio. Laborum illo nihil voluptate at quaerat ducimus, aliquid numquam, blanditiis nisi, sed pariatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus accusamus doloribus voluptas repudiandae est, beatae amet at consectetur, aperiam ipsa nisi sed cumque enim doloremque maxime quae voluptates inventore illo? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, obcaecati, asperiores in ullam nostrum placeat atque deserunt, quisquam repellat est voluptatem suscipit assumenda dolores sapiente fuga expedita. Ducimus, asperiores quaerat! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, exercitationem illum, sapiente iure quo eius repudiandae tempore itaque rerum corrupti mollitia quasi eveniet aliquam ducimus, dolores dolorum quia deserunt est. */}
                            </Typography>
                            <IconButton sx={{ p: 0 }}>
                                <Avatar src={userContext.providerLookup.get(authProvider.providerId)?.photoURL ?? undefined} />
                            </IconButton>
                            <div>displayed name: {userContext.providerLookup.get(authProvider.providerId)?.displayName}</div>
                            <div>email: {userContext.providerLookup.get(authProvider.providerId)?.email}</div>
                            <div>id: {userContext.providerLookup.get(authProvider.providerId)?.uid}</div>
                        </React.Fragment>
                    )}
                    {!userContext.providerLookup.has(authProvider.providerId ?? '') && (
                        <React.Fragment>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {name}:
                                {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Impedit, quas tempora magni minima et commodi quia ipsum, cumque quo illo ea. Dicta perferendis dolor recusandae totam quas, voluptates adipisci eos! Lorem ipsum, dolor sit amet consectetur adipisicing elit. Eligendi dicta totam impedit ratione saepe qui voluptatem distinctio. Laborum illo nihil voluptate at quaerat ducimus, aliquid numquam, blanditiis nisi, sed pariatur. Lorem ipsum dolor sit amet consectetur, adipisicing elit. Temporibus accusamus doloribus voluptas repudiandae est, beatae amet at consectetur, aperiam ipsa nisi sed cumque enim doloremque maxime quae voluptates inventore illo? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Magnam, obcaecati, asperiores in ullam nostrum placeat atque deserunt, quisquam repellat est voluptatem suscipit assumenda dolores sapiente fuga expedita. Ducimus, asperiores quaerat! Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere, exercitationem illum, sapiente iure quo eius repudiandae tempore itaque rerum corrupti mollitia quasi eveniet aliquam ducimus, dolores dolorum quia deserunt est. */}
                            </Typography>
                            <IconButton sx={{ p: 0 }} onClick={handleLink(authProvider)}>
                                <Avatar src={userContext.providerLookup.get(authProvider.providerId)?.photoURL ?? undefined} />
                                Link
                            </IconButton>
                            <div></div>
                        </React.Fragment>
                    )}
                </div>
            ))}
        </React.Fragment>
    );
}
