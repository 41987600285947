import LinkData from '../LinkData/LinkData';

type PageData = {
    readonly description: string,
    readonly text?: string,
    readonly element?: React.ReactNode,
    readonly links: Array<LinkData>,
    readonly path: string,
    readonly title: string,
    prev?: PageData,
    next?: PageData
}

// **************************** Pages ****************************** //

const homeRedirectPage: PageData = {
    description: 'Return to the home page',
    path: '/',
    title: 'Home',
    links: [],
};

const homePage: PageData = {
    description: 'Return to the home page',
    path: '/tutor',
    title: 'Home',
    links: [],
};

const profileSettingsPage: PageData = {
    description: 'View profile settings.',
    path: '/tutor/profile/settings',
    title: 'Home',
    links: [],
};


const contactUsPage: PageData = {
    description: 'Contact us',
    path: '/tutor/contact',
    title: 'Contact Us',
    links: [],
};

const currentCoursePage: PageData = {
    description: 'View what\'s going on in our current course',
    path: '/tutor/current',
    title: 'Current Course',
    links: [],
};

const learningPathsPage: PageData = {
    description: 'Use our video guide to learn specific topics',
    path: '/tutor/paths',
    title: 'Learning Paths',
    links: [],
};

const abstractLearningPathHomePage: PageData = {
    description: 'Use our video guide to learn a specific topic',
    path: '/tutor/path/:pathId',
    title: 'Learning Path',
    links: [],
};

const abstractLearningPathStridePage: PageData = {
    description: 'Use our video guide to learn a specific topic',
    path: '/tutor/path/:pathId/stride/:strideId',
    title: 'Learning Path - Stride',
    links: [],
};

const abstractLearningPathTangentPage: PageData = {
    description: 'Use our video guide to learn a specific topic',
    path: '/tutor/path/:pathId/stride/:strideId/tangent/:tangentIds',
    title: 'Learning Path - Tangent',
    links: [],
}

const schedulePage: PageData = {
    description: 'View our schedule',
    path: '/tutor/schedule',
    title: 'Schedule',
    links: [],
};

const supportUsPage: PageData = {
    description: 'Support us',
    path: '/tutor/support',
    title: 'Support Us',
    links: [],
};

const videosPage: PageData = {
    description: 'View our videos',
    path: '/tutor/videos',
    title: 'Videos',
    links: [],
};

export default PageData;

export {
    homePage,
    homeRedirectPage,
    profileSettingsPage,
    contactUsPage,
    currentCoursePage,
    learningPathsPage,
    schedulePage,
    supportUsPage,
    videosPage,

    abstractLearningPathHomePage,
    abstractLearningPathStridePage,
    abstractLearningPathTangentPage,
};