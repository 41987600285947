import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useDataContext, DataLoading, Stride } from '../../Components';
import Data, { PathData, StrideData } from '../../Data';
import { useParams } from "react-router-dom";

export default function TangentPage() {
    const { data, isLoading } = useDataContext();
    const { pathId, strideId, tangentIds: tangentIdsStr } = useParams();

    function getPath(pathId: string | undefined, isLoading: boolean, data: Data | undefined): PathData | undefined {
        if (isLoading || data == undefined || pathId == undefined) {
            return undefined;
        } else {
            return new PathData({
                data: data.paths.get(pathId)!,
                strides: data.strides,
                paths: data.paths,
            })
        }
    }

    function getStride(strideId: string | undefined, isLoading: boolean, data: Data | undefined): StrideData | undefined {
        if (isLoading || data == undefined || strideId == undefined) {
            return undefined;
        } else {
            return new StrideData({
                data: data.strides.get(strideId)!,
                steps: data.steps,
                strides: data.strides,
                videos: data.videos,
                images: data.images,
                clips: data.clips,
            })
        }
    }

    const [path, setPath] = useState<PathData | undefined>(() => getPath(pathId, isLoading, data));
    const [stride, setStride] = useState<StrideData | undefined>(() => getStride(strideId, isLoading, data));
    const [tangentIds, setTangentIds] = useState<string[]>(() => tangentIdsStr!.split(','));
    const [previousTangents, setPreviousTangents] = useState<StrideData[]>(() => tangentIds.map((tangentId) => getStride(tangentId, isLoading, data)!));

    const [strideTitle, setStrideTitle] = useState<string | undefined>(() => isLoading ? 'Learning Stride' : undefined);

    useEffect(() => {
        setPath(getPath(pathId, isLoading, data));
        setStride(getStride(strideId, isLoading, data));
        const tangentIds = tangentIdsStr!.split(',');
        setTangentIds(tangentIds);
        setPreviousTangents(tangentIds.map((tangentId) => getStride(tangentId, isLoading, data)!));

        setStrideTitle(isLoading ? 'Learning Stride' : undefined);
    }, [pathId, tangentIdsStr, data, isLoading]);

    return (
        <React.Fragment>
            <DataLoading title={strideTitle}>
                {stride != undefined && path != undefined && (
                    <Stride data={stride!} path={path!} previousTangents={previousTangents} />
                )}
                {(stride == undefined || path == undefined) && (
                    <Typography variant="body1" component="div">
                        There was an error loading data.
                    </Typography>
                )}
            </DataLoading>
        </React.Fragment>
    );
}