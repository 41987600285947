import { TextData } from "../TextData";

export type EmbeddedVideo = ClipData | VideoData | SimpleVideoData;

export type ClipDataInputs = {
    data: SimpleClipData,
    videos: Map<string, SimpleVideoData>,
}

export type SimpleVideoData = {
    readonly id: string,
    readonly name: string,
    readonly description: TextData,
    readonly youTubeId: string,
    readonly tags: string[],
    readonly sortByDate: string | number | Date,

    // Defined for embedded video to use with clips.
    readonly start?: undefined;
    readonly end?: undefined;
}

export type SimpleClipData = {
    readonly id: string,
    readonly videoId: string,
    readonly start: number,
    readonly end: number,
}

export default class VideoData {
    readonly id: string;
    readonly name: string;
    readonly description: TextData;
    readonly youTubeId: string;
    readonly tags: string[];
    readonly sortByDate: Date;

    // Defined for embedded video to use with clips.
    readonly start?: undefined;
    readonly end?: undefined;

    constructor({
        id,
        name,
        description,
        youTubeId,
        tags,
        sortByDate
    }: SimpleVideoData) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.youTubeId = youTubeId;
        this.tags = tags;
        this.sortByDate = new Date(sortByDate);
    }
}

export class ClipData {
    readonly id: string;
    readonly videoId: string;
    readonly start: number;
    readonly end: number;

    readonly name: string;
    readonly description: TextData;
    readonly youTubeId: string;
    readonly tags: string[];
    readonly sortByDate: Date;

    constructor({
        data: {
            id,
            videoId,
            start,
            end,
        },
        videos,
    }: ClipDataInputs) {
        this.id = id;
        this.videoId = videoId;
        this.start = start;
        this.end = end;
        const { name, description, youTubeId, tags, sortByDate } = videos.get(videoId)!;
        this.name = name;
        this.description = description;
        this.youTubeId = youTubeId;
        this.tags = tags;
        this.sortByDate = new Date(sortByDate);
    }
}