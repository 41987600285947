import React, { useState, useEffect } from 'react';
import { useDataContext, DataLoading } from '../../Components';
import { SimplePathData, PathData, SimpleStrideData } from '../../Data';
import { PathList } from '../../Components/Path';

export default function LearningPaths() {
    const { data, isLoading } = useDataContext();

    function getPaths(paths: Map<string, SimplePathData> | undefined, strides: Map<string, SimpleStrideData> | undefined): PathData[] {
        const pathArray: SimplePathData[] = Array.from(paths?.values() ?? []);
        return pathArray.map((simplePath) => new PathData({ data: simplePath, strides: strides!, paths: paths! }));
    }

    const [paths, setPaths] = useState<PathData[]>(() => getPaths(data?.paths, data?.strides));

    useEffect(() => {
        if (isLoading) {
            setPaths([]);
        } else {
            setPaths(getPaths(data?.paths, data?.strides));
        }
    }, [data, isLoading]);

    return (
        <React.Fragment>
            <DataLoading title={isLoading ? "Learning Paths" : undefined}>
                <PathList paths={paths} title="Learning Paths" />
            </DataLoading>
        </React.Fragment>
    );
}