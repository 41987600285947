type LinkData = {
    readonly ariaLabel: string,
    readonly text: string,
    readonly to: string,
    readonly target?: string,
    readonly rel?: string,
}

export default LinkData;

const isLinkData = (object: any): boolean => {
    return (object as LinkData).text !== undefined;
}

export {
    isLinkData
}