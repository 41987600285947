import {
    AuthError,
    AuthErrorCodes
} from "firebase/auth";

export const produceErrorMessage = (error: AuthError, action: string): string => {
    switch (error.code) {
        case AuthErrorCodes.INVALID_PASSWORD:
        case AuthErrorCodes.INVALID_EMAIL:
            return 'Incorrect email or password.';
        case AuthErrorCodes.EMAIL_EXISTS:
            return 'Email already exists.';
        case AuthErrorCodes.POPUP_BLOCKED:
            return 'Popup blocked, please allow popups and try again.';
        case AuthErrorCodes.POPUP_CLOSED_BY_USER:
            return `${action} canceled by closing popup, please try again.`;
        case AuthErrorCodes.EXPIRED_POPUP_REQUEST:
            return `Popup connection has expired, please try again.`;
        case AuthErrorCodes.REDIRECT_CANCELLED_BY_USER:
            return `${action} canceled by user, please try again.`;
        case AuthErrorCodes.REDIRECT_OPERATION_PENDING:
            return `${action} still pending.`
        default:
            return 'Unknown error, please try again later.';
    }
}