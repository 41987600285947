import * as React from 'react';
import { PageHub } from '../../Navigation'
import Box from '@mui/material/Box';
import { CenteredContainer, Copyright } from '../../Components';
import { TitleImage } from '../../Components';
import styles from './PageContent.module.scss';

export default function PageContent() {
    return (
        <div className={styles['page-content-container']}>
            <TitleImage />
            <div className={styles['page-content']}>
                <CenteredContainer>
                    <Box sx={{ my: 4 }}>
                        <PageHub />
                    </Box>
                </CenteredContainer>
            </div>
            <Copyright />
        </div>
    );
}
