import * as React from 'react';
import Typography from '@mui/material/Typography';
import title from './Images/Title.png';
import styles from './Title.module.scss';

export function TitleImage() {
    return (
        <div className={styles.title}>
            <img className={styles['title-image']} src={title} alt="taylor the tech tutor" />
        </div>
    );
}

export function TitleText() {
    return (
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Taylor The Tech Tutor
        </Typography>
    );
}
