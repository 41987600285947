import React, { useState, useEffect, PropsWithChildren } from 'react';
import axios from 'axios';
import { firebase } from '../../Authentication';
import { ref, getDownloadURL } from 'firebase/storage';
import { createDataFromJSONObject } from '../../Data';
import { DataContext, DataLoadingContext } from './DataContext';
import { getValue } from "firebase/remote-config";

const val = getValue(firebase.remoteConfig, "data");

const DataProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const [dataContext, setDataContext] = useState(() => {
        return {
            isLoading: true,
            hasError: false,
        } as DataLoadingContext;
    });

    useEffect(() => {
        const storageRef = ref(firebase.storage, val.asString());

        getDownloadURL(storageRef)
            .then(async (url) => {
                try {
                    const obj = await axios.get(url);
                    console.log('success');
                    console.log(JSON.stringify(obj));
                    const data = createDataFromJSONObject(obj.data);
                    setDataContext({
                        isLoading: false,
                        hasError: false,
                        data
                    });
                } catch (error) {
                    console.log('error');
                    console.log(JSON.stringify(error));
                    setDataContext({
                        isLoading: false,
                        hasError: true,
                        error
                    });
                }
            })
            .catch((error) => {
                console.log('error getting download url');
                console.log(JSON.stringify(error));
                // Handle any errors
                setDataContext({
                    isLoading: false,
                    hasError: true,
                    error
                });
            });
    }, []);


    return (
        <DataContext.Provider value={dataContext}>
            {children}
        </DataContext.Provider>
    );
}

export default DataProvider;