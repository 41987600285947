import * as React from 'react';
import { AppBar } from '../../Navigation';
import PageContent from '../PageContent';

export default function BrowserApp() {
    return (
        <React.Fragment>
            <AppBar />
            <PageContent />
        </React.Fragment>
    );
}
