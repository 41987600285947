import React, { useState, useEffect } from 'react';
import Text from '../../Text';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import MatStep from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { StrideData, SimpleStrideData, createTangentLink, createStrideLink, createPathLink, PathData } from '../../../Data';
import Step from '../Step';
import Tags from '../../Tags';
import { LinkData } from '../../../Navigation';
import BottomNavButtons, { BottomNavButtonInputs, BottomNavIconEnum } from '../../BottomNavButtons';
import TangentList from './TangentList';

export type StrideInputs = {
    data: StrideData;
    path: PathData;
    previousTangents: StrideData[];
}

export default function Stride({ data: stride, previousTangents, path }: StrideInputs) {
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});
    const [{ title, summary, tags, steps, tangentStrides }, setDisplayedStride] = useState<StrideData>(() => previousTangents[0] ?? stride)
    const index = path.strideIds.findIndex((strideId) => strideId == stride.id);

    // Build bottom nav buttons:
    const prevIndex = index > 0 ? index - 1 : -1;
    const prev = prevIndex >= 0 ?
        {
            link: createStrideLink({ path, stride: path.strides[prevIndex] }),
            icon: BottomNavIconEnum.ArrowBack,
            text: 'Prev',

        } as BottomNavButtonInputs :
        {
            link: createPathLink({ path }),
            icon: BottomNavIconEnum.ArrowBack,
            text: 'Return to path home',

        } as BottomNavButtonInputs;
    const nextIndex = index < path.strideIds.length - 1 ? index + 1 : -1;
    const next = nextIndex >= 0 ?
        {
            link: createStrideLink({ path, stride: path.strides[nextIndex] }),
            icon: BottomNavIconEnum.ArrowForward,
            text: 'Next',

        } as BottomNavButtonInputs :
        {
            link: createPathLink({ path }),
            icon: BottomNavIconEnum.ArrowForward,
            text: 'Complete path!',

        } as BottomNavButtonInputs;

    const back = previousTangents.length > 1 ?
        {
            link: createTangentLink({
                path,
                stride,
                tangent: previousTangents[1],
                previousTangentPath: previousTangents.slice(2).map((tangent) => tangent.id).join(',')
            }),
            icon: BottomNavIconEnum.Close,
            text: 'Leave tangent',

        } as BottomNavButtonInputs :
        {
            link: createStrideLink({ path, stride }),
            icon: BottomNavIconEnum.ExitToApp,
            text: 'Return to active path',

        } as BottomNavButtonInputs;
    const middleButtons = previousTangents.length > 0 ? [
        back
    ] : [];
    // End build bottom nav buttons.

    useEffect(() => {
        setDisplayedStride(previousTangents[0] ?? stride);
    }, [stride, tangentStrides, previousTangents]);

    const [activeStep, setActiveStep] = useState(0);

    const complete = (activeStep: number) => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
    };

    const handleNext = () => {
        complete(activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleStep = (index: number) => () => {
        complete(activeStep);
        if (index == activeStep) {
            setActiveStep(-1);
        } else {
            setActiveStep(index);
        }
    };

    const handleBack = () => {
        complete(activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div>
            <Typography variant="h3" component="h1">
                {title}
            </Typography>
            <Tags tags={tags} />
            <Typography variant="body1" component="div">
                <Text textData={summary} />
            </Typography>
            <Stepper activeStep={activeStep} nonLinear orientation="vertical">
                {steps.map((step, index) => (
                    <MatStep key={`${path.id}.${stride.id}.${step.id}`} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                            <StepLabel
                                optional={
                                    index === 2 ? (
                                        <Typography variant="caption">Last step</Typography>
                                    ) : null
                                }
                            >
                                {step.title}
                            </StepLabel>
                        </StepButton>
                        <StepContent>
                            <Step {...step} />
                            <Box sx={{ mb: 2 }}>
                                <div>
                                    <Button
                                        variant="contained"
                                        onClick={handleNext}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                    </Button>
                                    <Button
                                        disabled={index === 0}
                                        onClick={handleBack}
                                        sx={{ mt: 1, mr: 1 }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </Box>
                        </StepContent>
                    </MatStep>
                ))}
            </Stepper>
            <TangentList tangents={tangentStrides} stride={stride} path={path} previousTangents={previousTangents} hideOnEmpty />
            <BottomNavButtons next={next} prev={prev} middleButtons={middleButtons} />
        </div>
    );
}