import { type TextData } from '../../TextData';
import ImageData from '../../ImageData';
import { SimpleVideoData, SimpleClipData } from '../../VideoData';
import StepData, { SimpleStepData } from '../StepData';
import { LinkData } from '../../../Navigation';
import PathData, { SimplePathData } from '../PathData';

export type SimpleStrideData = {
    readonly id: string,
    readonly stepIds: string[],
    readonly title: string,
    readonly summary: TextData,
    readonly shortDescription: string,
    readonly tags: string[],
    readonly tangentStrideIds: string[],
}

export type StrideDataInput = {
    readonly data: SimpleStrideData,
    readonly steps: Map<string, SimpleStepData>,
    readonly strides: Map<string, SimpleStrideData>,
    readonly images: Map<string, ImageData>,
    readonly videos: Map<string, SimpleVideoData>,
    readonly clips: Map<string, SimpleClipData>,
}

export type StrideLinkInputs = {
    readonly path: SimplePathData | PathData,
    readonly stride: SimpleStrideData | StrideData,
    readonly index?: number | undefined,
}

export type TangentLinkInputs = {
    readonly path: SimplePathData | PathData,
    readonly stride: SimpleStrideData | StrideData,
    readonly tangent: SimpleStrideData | StrideData,
    readonly previousTangentPath?: string | undefined,
    readonly index?: number | undefined,
}

export default class StrideData {
    readonly id: string;
    readonly stepIds: string[];
    readonly title: string;
    readonly summary: TextData;
    readonly shortDescription: string;
    readonly tags: string[];
    readonly tangentStrideIds: string[];
    readonly steps: StepData[];
    readonly tangentStrides: SimpleStrideData[];

    constructor({
        data: {
            id,
            stepIds,
            title,
            summary,
            shortDescription,
            tags,
            tangentStrideIds,
        },
        steps,
        strides,
        images,
        videos,
        clips,
    }: StrideDataInput) {
        this.id = id;
        this.stepIds = stepIds;
        this.title = title;
        this.summary = summary;
        this.shortDescription = shortDescription;
        this.tags = tags;
        this.tangentStrideIds = tangentStrideIds;
        const newSteps = [];
        for (const stepId of this.stepIds) {
            newSteps.push(new StepData({
                data: steps.get(stepId)!,
                images,
                videos,
                clips,
            }));
        }
        this.steps = newSteps;
        const newTangentStrides = [];
        for (const strideId of this.tangentStrideIds) {
            newTangentStrides.push(strides.get(strideId)!);
        }
        this.tangentStrides = newTangentStrides;
    }
}

export function createStrideLink({ path, stride, index = undefined }: StrideLinkInputs): LinkData {
    const indexStr = (index != undefined) ? `${index}. ` : '';
    return {
        to: `/tutor/path/${path.id}/stride/${stride.id}`,
        text: `${indexStr}${stride.title}`,
        ariaLabel: stride.shortDescription,
    } as LinkData;
}

export function createTangentLink({ path, stride, tangent, previousTangentPath = undefined, index = undefined }: TangentLinkInputs): LinkData {
    const indexStr = (index != undefined) ? `${index}. ` : '';
    const tangentPathStr = (previousTangentPath != undefined && previousTangentPath.trim() != '') ? `${tangent.id},${previousTangentPath}` : tangent.id;
    return {
        to: `/tutor/path/${path.id}/stride/${stride.id}/tangent/${tangentPathStr}`,
        text: `${indexStr}${tangent.title}`,
        ariaLabel: tangent.shortDescription,
    } as LinkData;
}