import React from 'react';
import {
    RouteObject
} from 'react-router-dom';
import PageData, {
    homePage,
    homeRedirectPage,
    profileSettingsPage,
    contactUsPage,
    currentCoursePage,
    learningPathsPage,
    schedulePage,
    supportUsPage,
    videosPage,
    abstractLearningPathHomePage,
    abstractLearningPathStridePage,
    abstractLearningPathTangentPage,
} from './PageData';
import {
    homeRedirectLoader,
    Home,
    profileSettingsRedirectLoader,
    ProfileSettings,
    ContactUs,
    CurrentCourse,
    LearningPaths,
    Schedule,
    SupportUs,
    Videos,
    PathHome,
    StridePage,
    TangentPage,
} from '../../Pages';

function getLinkDataFromPageData(pageData: PageData, element: React.ReactNode, loaderFunc: (() => Promise<any>) | undefined = undefined): RouteObject {
    return {
        index: pageData.path === '/',
        path: pageData.path === '/' ? undefined : pageData.path,
        name: pageData.title,
        element: element,
        loader: loaderFunc,
    } as RouteObject;
}

const routes: Array<RouteObject> = [
    getLinkDataFromPageData(contactUsPage, <ContactUs />),
    getLinkDataFromPageData(profileSettingsPage, <ProfileSettings />, profileSettingsRedirectLoader),
    getLinkDataFromPageData(abstractLearningPathHomePage, <PathHome />),
    getLinkDataFromPageData(abstractLearningPathStridePage, <StridePage />),
    getLinkDataFromPageData(abstractLearningPathTangentPage, <TangentPage />),
    getLinkDataFromPageData(currentCoursePage, <CurrentCourse />),
    getLinkDataFromPageData(learningPathsPage, <LearningPaths />),
    getLinkDataFromPageData(schedulePage, <Schedule />),
    getLinkDataFromPageData(supportUsPage, <SupportUs />),
    getLinkDataFromPageData(videosPage, <Videos />),
    getLinkDataFromPageData(homePage, <Home />),
    getLinkDataFromPageData(homeRedirectPage, <Home />, homeRedirectLoader),
]

export default routes;