import React, { useState, useEffect } from 'react';
import { ImageData } from '../../Data';
import { firebase } from '../../Authentication';
import { ref, getDownloadURL } from 'firebase/storage';

export default function Image({ src, alt }: ImageData) {
    const [realSrc, setRealSrc] = useState<string | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const storageRef = ref(firebase.storage, src);

        getDownloadURL(storageRef)
            .then(async (url) => {
                setRealSrc(url);
                setIsLoading(false);
            })
            .catch((_) => {
                setIsLoading(false);
            });
    }, []);

    return (
        <React.Fragment>
            {isLoading && (
                <React.Fragment>
                    Loading...
                </React.Fragment>
            )}
            {!isLoading && realSrc != undefined && (
                <img src={realSrc} alt={alt} />
            )}
            {!isLoading && realSrc == undefined && (
                <React.Fragment>
                    Unknown error.
                </React.Fragment>
            )}
        </React.Fragment>
    );
}