import React from 'react';
import Box from '@mui/material/Box';
import { FabLink, type FabLinkInputs } from '../Link';

export type BottomNavInputs = {
    next: FabLinkInputs,
    prev: FabLinkInputs,
    middleButtons: FabLinkInputs[],
}

export default function BottomNavButtons({ next, prev, middleButtons }: BottomNavInputs) {
    return (
        <Box sx={{ '& > :not(style)': { m: 1 } }}>
            <FabLink {...prev} />
            {middleButtons.map((navButton) => (
                <FabLink key={navButton.link.to} {...navButton} />
            ))}
            <FabLink {...next} />
        </Box>
    );
}