import { createContext, useContext } from 'react';
import Data from '../../Data';

export type DataLoadingContext = {
    data?: Data | undefined,
    isLoading: boolean,
    hasError: boolean,
    error?: any | undefined,
}

export const DataContext = createContext<DataLoadingContext | null>(null);

export const useDataContext = () => {
    const dataContext = useContext(DataContext);

    if (!dataContext) {
        throw new Error(
            "useUserActionsContext has to be used within <UserActionsContext.Provider>"
        );
    }
    return dataContext;
};