import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { supportedProviders, SupportedProvider, useUserActionsContext } from '../UserContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

export interface LoginProps {
    open: boolean;
    onClose: () => void;
}

export default function Login({ onClose, open }: LoginProps) {
    const userActions = useUserActionsContext();
    const [error, setError] = useState<string | null>(null);

    const handleClose = () => {
        onClose();
    };

    const handleLogin = (authProvider: SupportedProvider) => async (event: React.MouseEvent<HTMLElement>) => {
        try {
            await userActions.login(authProvider);
            setError(null);
            onClose();
        } catch (e: any) {
            setError((e as Error).message);
        }
    };

    return (
        <React.Fragment>
            <Dialog onClose={handleClose} open={open} keepMounted={true} disablePortal={true}>
                <List>
                    {supportedProviders.map((provider) => (
                        <ListItem key={provider.authProvider.providerId}>
                            <ListItemButton onClick={handleLogin(provider.authProvider)}>
                                <ListItemIcon>
                                    {provider.Icon}
                                </ListItemIcon>
                                <ListItemText primary={`Sign in with ${provider.name}`} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
                <div>{error}</div>
            </Dialog>
        </React.Fragment >
    )
}