import React from 'react';
import Text from '../../Text';
import { StepData } from '../../../Data';
import Image from '../../Image';
import Video from '../../Video';
import Box from '@mui/material/Box';

export default function Step({ image, video, text }: StepData) {
    return (
        <Box>
            {image != undefined && (
                <Image {...image} />
            )}
            {video != undefined && (
                <Video data={video} />
            )}
            <Text textData={text} />
        </Box>
    );
}