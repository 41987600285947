import React from 'react';
import { LinkData } from '../../Navigation';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {
    Link as RouterLink,
    LinkProps as RouterLinkProps,
} from 'react-router-dom';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>(function Link(
    itemProps,
    ref,
) {
    return <RouterLink ref={ref} {...itemProps} role={undefined} />;
});

export type ListItemLinkInputs = {
    data: LinkData,
    labelId: string,
    shortDescription: string,
}

export default function ListItemLink({ data: { to, ariaLabel, text, rel, target }, labelId, shortDescription }: ListItemLinkInputs) {
    return (
        <ListItemButton component={Link} to={to} rel={rel} target={target} aria-label={ariaLabel}>
            <ListItemText id={labelId} primary={text} secondary={shortDescription} />
        </ListItemButton>
    );
}