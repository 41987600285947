import React from 'react';
import { createTangentLink, SimpleStrideData, StrideData, PathData } from '../../../Data';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import { ListItemLink } from '../../Link';

export type TangentListInputs = {
    tangents: SimpleStrideData[] | StrideData[],
    stride: SimpleStrideData | StrideData,
    previousTangents: SimpleStrideData[] | StrideData[],
    path: PathData,
    showNumbers?: boolean | undefined,
    hideOnEmpty?: boolean | undefined,
}

export default function TangentList({ tangents, stride, previousTangents, path, showNumbers, hideOnEmpty }: TangentListInputs) {
    const filteredTangents = tangents
        .filter((tangent) => tangent.id != stride.id && !previousTangents.some((previousTangent) => tangent.id == previousTangent.id));
    const previousTangentPath = previousTangents.map((tangent) => tangent.id).join(',');
    const tangentLinks = filteredTangents
        .map((tangent, index) => createTangentLink({ stride, path, tangent, previousTangentPath, index: (showNumbers == true ? index + 1 : undefined) }));
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const handleToggle = (index: number) => () => {
        const newCompleted = completed;
        newCompleted[index] = true;
        setCompleted(newCompleted);
    };

    if (hideOnEmpty && filteredTangents.length == 0) {
        return null;
    }
    return (
        <React.Fragment>
            <Typography variant="h4" component="h1">
                Tangents:
            </Typography>
            {filteredTangents.length > 0 && (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {filteredTangents.map((tangent, index) => {
                        const labelId = `checkbox-list-label-${tangent.id}`;
                        const link = tangentLinks[index];

                        return (
                            <ListItem
                                key={tangent.id}
                                secondaryAction={
                                    <Checkbox
                                        onClick={handleToggle(index)}
                                        edge="start"
                                        checked={completed[index]}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemLink data={link} labelId={labelId} shortDescription={tangent.shortDescription} />
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {filteredTangents.length == 0 && (
                <Typography>
                    No tangents found.
                </Typography>
            )}
        </React.Fragment>
    );
}