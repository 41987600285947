import { type TextData } from '../TextData';
import { SimpleStrideData } from './StrideData';
import { LinkData } from '../../Navigation';

export type SimplePathData = {
    readonly id: string,
    readonly strideIds: string[],
    readonly title: string,
    readonly summary: TextData,
    readonly shortDescription: string,
    readonly tags: string[],
    readonly similarPathIds: string[],
    readonly sortByDate: string | number | Date,
}

export type PathDataInput = {
    readonly data: SimplePathData,
    readonly strides: Map<string, SimpleStrideData>,
    readonly paths: Map<string, SimplePathData>,
}

export type PathLinkInputs = {
    readonly path: SimplePathData | PathData,
}

export default class PathData {
    readonly id: string;
    readonly strideIds: string[];
    readonly title: string;
    readonly summary: TextData;
    readonly shortDescription: string;
    readonly tags: string[];
    readonly similarPathIds: string[];
    readonly sortByDate: Date;
    readonly strides: SimpleStrideData[];
    readonly similarPaths: SimplePathData[];

    constructor({
        data: {
            id,
            strideIds,
            title,
            summary,
            shortDescription,
            tags,
            similarPathIds,
            sortByDate
        },
        strides,
        paths,
    }: PathDataInput) {
        this.id = id;
        this.strideIds = strideIds;
        this.title = title;
        this.summary = summary;
        this.shortDescription = shortDescription;
        this.tags = tags;
        this.similarPathIds = similarPathIds;
        this.sortByDate = new Date(sortByDate);
        const newStrides = [];
        for (const strideId of this.strideIds) {
            newStrides.push(strides.get(strideId)!);
        }
        this.strides = newStrides;
        const newSimilarPaths = [];
        for (const similarPathId of this.similarPathIds) {
            newSimilarPaths.push(paths.get(similarPathId)!);
        }
        this.similarPaths = newSimilarPaths;
    }
}

export function createPathLink({ path }: PathLinkInputs): LinkData {
    return {
        to: `/tutor/path/${path.id}`,
        text: `${path.title}`,
        ariaLabel: path.shortDescription,
    } as LinkData;
}