import React from 'react';
import { PropTypes } from '@mui/material';
import { LinkData } from '../../Navigation';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NavigationIcon from '@mui/icons-material/Navigation';
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

export enum FabIconEnum {
    Unknown,
    Navigation,
    Edit,
    Add,
    Favorite,
    ArrowForward,
    ArrowBack,
    QuestionMark,
    Close,
    ExitToApp,
};

export type FabLinkInputs = {
    icon: FabIconEnum,
    link: LinkData,
    text?: string | undefined,
    color?: PropTypes.Color | 'success' | 'error' | 'info' | 'warning' | undefined,
}

type FabIconInputs = {
    icon: FabIconEnum,
}

function FabIcon({ icon }: FabIconInputs) {
    switch (icon) {
        case FabIconEnum.ExitToApp:
            return <ExitToAppIcon />;
        case FabIconEnum.Close:
            return <CloseIcon />;
        case FabIconEnum.ArrowBack:
            return <ArrowBackIcon />;
        case FabIconEnum.ArrowForward:
            return <ArrowForwardIcon />;
        case FabIconEnum.Favorite:
            return <FavoriteIcon />;
        case FabIconEnum.Add:
            return <AddIcon />;
        case FabIconEnum.Edit:
            return <EditIcon />;
        case FabIconEnum.Navigation:
            return <NavigationIcon />;
        case FabIconEnum.QuestionMark:
        case FabIconEnum.Unknown:
        default:
            return <QuestionMarkIcon />;
    }
}

export default function FabLink({ link, icon, text, color }: FabLinkInputs) {
    return (
        <Fab
            href={link.to}
            rel={link.rel}
            aria-label={link.ariaLabel}
            target={link.target}
            variant={text != undefined ? 'extended' : 'circular'}
            color={color}>
            <FabIcon icon={icon} />
            {text}
        </Fab>
    );
}