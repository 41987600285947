import * as React from 'react';
import { AppBar } from '../../Navigation';
import PageContent from '../PageContent';

export default function MobileApp() {
    return (
        <React.Fragment>
            <PageContent />
            <AppBar anchor="bottom" />
        </React.Fragment>
    );
}
