import React from 'react';
import { LinkData } from '../../Navigation';
import MuiLink from '@mui/material/Link';

export default function Link({ to, ariaLabel, text, rel, target }: LinkData) {
    return (
        <MuiLink href={to} aria-label={ariaLabel} rel={rel} target={target}>
            {text}
        </MuiLink>
    );
}