import React from 'react';
import { createStrideLink, createTangentLink, SimpleStrideData, StrideData, PathData } from '../../../Data';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import { ListItemLink } from '../../Link';

export type StrideListInputs = {
    strides: SimpleStrideData[] | StrideData[],
    path: PathData,
    showNumbers?: boolean | undefined,
    hideOnEmpty?: boolean | undefined,
}

export default function StrideList({ strides, path, showNumbers, hideOnEmpty = false }: StrideListInputs) {
    const strideLinks = strides.map((stride, index) => createStrideLink({ stride, path, index: (showNumbers == true ? index + 1 : undefined) }));
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const handleToggle = (index: number) => () => {
        const newCompleted = completed;
        newCompleted[index] = true;
        setCompleted(newCompleted);
    };

    if (hideOnEmpty && strides.length == 0) {
        return null;
    }

    return (
        <React.Fragment>
            <Typography variant="h4" component="h1">
                Strides:
            </Typography>
            {strides.length > 0 && (
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {strides.map((stride, index) => {
                        const labelId = `checkbox-list-label-${stride.id}`;
                        const link = strideLinks[index];

                        return (
                            <ListItem
                                key={stride.id}
                                secondaryAction={
                                    <Checkbox
                                        onClick={handleToggle(index)}
                                        edge="start"
                                        checked={completed[index]}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ 'aria-labelledby': labelId }}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemLink data={link} labelId={labelId} shortDescription={stride.shortDescription} />
                            </ListItem>
                        );
                    })}
                </List>
            )}
            {strides.length == 0 && (
                <Typography>
                    No strides found.
                </Typography>
            )}
        </React.Fragment>
    );
}