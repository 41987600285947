import React, { useState, useEffect, PropsWithChildren } from 'react';
import firebase from '../firebase';
import {
    User,
    UserInfo,
    unlink,
    signOut,
    signInWithRedirect,
    linkWithPopup,
    AuthError,
    getRedirectResult,
    UserCredential,
} from "firebase/auth";
import { produceErrorMessage } from '../AuthErrorHandling';
import { useSearchParams } from "react-router-dom";
import { UserContext, UserActionsContext, SupportedProvider, RockBottomGamesUserContext, RockBottomGamesUserActionsContext } from './UserContext';

const UserProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    let [searchParams, setSearchParams] = useSearchParams();
    const getUserContext = (user: User | null, prevUserContext: RockBottomGamesUserContext | null = null): RockBottomGamesUserContext => {
        const providerLookup = (user?.providerData ?? ([] as UserInfo[])).reduce(
            (prev: Map<string, UserInfo>, curr: UserInfo | null) => {
                if (curr == null) {
                    return prev;
                }
                const next = new Map<string, UserInfo>(prev);
                next.set(curr!.providerId, curr);
                return next;
            }, new Map<string, UserInfo>());
        return {
            ...prevUserContext,
            currentUser: user,
            providerLookup
        };
    }

    const [userContext, setUserContext] = useState(() => {
        const jsonUser = sessionStorage.getItem('user');
        const user = firebase.auth.currentUser ?? jsonUser != null ? (JSON.parse(jsonUser!) as User | null) : null;
        return getUserContext(user);
    });

    const updateUserContext = (updatedUser: User | null) => {
        const newUserContext = getUserContext(updatedUser, userContext);
        setUserContext(newUserContext);
        sessionStorage.setItem('user', JSON.stringify(updatedUser));
        return newUserContext;
    }

    const userActionsContext: RockBottomGamesUserActionsContext = {
        logout: async () => {
            if (userContext.currentUser == null) {
                return;
            }
            try {
                await signOut(firebase.auth);
                updateUserContext(null);
            } catch (e) {
                throw new Error(produceErrorMessage(e as AuthError, 'Logout'));
            }
        },
        unlink: async (providerId: string) => {
            if (userContext.currentUser == null) {
                return;
            }
            try {
                const updatedUser = await unlink(userContext.currentUser!, providerId);
                updateUserContext(updatedUser);
            } catch (e) {
                throw new Error(produceErrorMessage(e as AuthError, 'Unlink account'));
            }
        },
        login: async (provider: SupportedProvider) => {
            if (userContext.currentUser != null) {
                return;
            }
            setSearchParams({
                ...searchParams,
                'login': provider.providerId
            });
            try {
                await signInWithRedirect(firebase.auth, provider);
            } catch (e) {
                setSearchParams({
                    ...searchParams,
                    'login': ''
                });
                throw new Error(produceErrorMessage(e as AuthError, 'Login'));
            }
        },
        link: async (provider: SupportedProvider) => {
            if (userContext.currentUser == null) {
                return;
            }
            try {
                const updatedUserCredentials = await linkWithPopup(userContext.currentUser, provider);
                updateUserContext(updatedUserCredentials.user);
            } catch (e) {
                throw new Error(produceErrorMessage(e as AuthError, 'Link account'));
            }
        },
    };

    useEffect(() => {
        getRedirectResult(firebase.auth).then(function (result: UserCredential | null) {
            if (result == null) {
                return;
            }
            if (searchParams.get('login') != null) {
                updateUserContext(result.user);
                const newSearchParams = new URLSearchParams(searchParams);
                newSearchParams.delete('login');
                setSearchParams(newSearchParams);
            }
        }).catch(function (error) {
        });
        firebase.auth.onAuthStateChanged((updatedUser: User | null) => (updateUserContext(updatedUser)));
    }, []);


    return (
        <UserContext.Provider value={userContext}>
            <UserActionsContext.Provider value={userActionsContext}>
                {children}
            </UserActionsContext.Provider>
        </UserContext.Provider>
    );
}

export default UserProvider;