import { SimpleVideoData, SimpleClipData } from './VideoData';
import { SimpleStepData, SimpleStrideData, SimplePathData } from './PathData';
import ImageData from './ImageData';

type Data = {
    readonly videos: Map<string, SimpleVideoData>;
    readonly clips: Map<string, SimpleClipData>;
    readonly steps: Map<string, SimpleStepData>;
    readonly strides: Map<string, SimpleStrideData>;
    readonly paths: Map<string, SimplePathData>;
    readonly images: Map<string, ImageData>;
}

export default Data;

export function createJSONfromData(data: Data): string {
    const videos = Object.fromEntries(data.videos);
    const clips = Object.fromEntries(data.clips);
    const steps = Object.fromEntries(data.steps);
    const strides = Object.fromEntries(data.strides);
    const paths = Object.fromEntries(data.paths);
    const images = Object.fromEntries(data.images);
    return JSON.stringify({
        videos,
        clips,
        steps,
        strides,
        paths,
        images,
    });
}

export function createDataFromJSONObject(obj: any): Data {
    console.log('Translating videos...');
    console.log(JSON.stringify(obj['videos']));
    const videos = new Map<string, SimpleVideoData>(Object.entries(obj['videos']));
    console.log('Translating videos success!');
    console.log('Translating clips...');
    console.log(JSON.stringify(obj['clips']));
    const clips = new Map<string, SimpleClipData>(Object.entries(obj['clips']));
    console.log('Translating clips success!');
    console.log('Translating steps...');
    const steps = new Map<string, SimpleStepData>(Object.entries(obj['steps']));
    console.log('Translating steps success!');
    console.log('Translating strides...');
    const strides = new Map<string, SimpleStrideData>(Object.entries(obj['strides']));
    console.log('Translating strides success!');
    console.log('Translating paths...');
    const paths = new Map<string, SimplePathData>(Object.entries(obj['paths']));
    console.log('Translating paths success!');
    console.log('Translating images...');
    const images = new Map<string, ImageData>(Object.entries(obj['images']));
    console.log('Translating images success!');
    return {
        videos,
        clips,
        steps,
        strides,
        paths,
        images,
    } as Data;
}

export function createDataFromJSONString(json: string): Data {
    const obj = JSON.parse(json);
    return createDataFromJSONObject(obj);
}