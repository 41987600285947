import React, { createContext, useContext } from "react";
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    GithubAuthProvider,
    User,
    UserInfo
} from "firebase/auth";
import MailIcon from '@mui/icons-material/Mail';
import GoogleIcon from '@mui/icons-material/Google';
import GitHubIcon from '@mui/icons-material/GitHub';

export type SupportedProvider =
    EmailAuthProvider |
    GoogleAuthProvider |
    GithubAuthProvider;

type AuthDef = {
    authProvider: SupportedProvider,
    name: string,
    Icon: JSX.Element,
}

export const supportedProviders: AuthDef[] = [
    {
        authProvider: new EmailAuthProvider(),
        name: "Email",
        Icon: <MailIcon />,
    },
    {
        authProvider: new GoogleAuthProvider(),
        name: "Google",
        Icon: <GoogleIcon />,
    },
    {
        authProvider: new GithubAuthProvider(),
        name: "GitHub",
        Icon: <GitHubIcon />,
    },
];
export type RockBottomGamesUserContext = {
    currentUser: User | null,
    providerLookup: Map<string, UserInfo>,
}

export const UserContext = createContext<RockBottomGamesUserContext | null>(null);

export const useUserContext = () => {
    const userContext = useContext(UserContext);

    if (!userContext) {
        throw new Error(
            "useUserContext has to be used within <UserContext.Provider>"
        );
    }
    return userContext;
};

type LoginCallback = (provider: SupportedProvider) => Promise<void>;
type LinkCallback = (provider: SupportedProvider) => Promise<void>;
type LogoutCallback = () => void;
type UnlinkCallback = (providerId: string) => void;
export type RockBottomGamesUserActionsContext = {
    login: LoginCallback,
    link: LinkCallback,
    logout: LogoutCallback,
    unlink: UnlinkCallback,
}

export const UserActionsContext = createContext<RockBottomGamesUserActionsContext | null>(null);

export const useUserActionsContext = () => {
    const userActionsContext = useContext(UserActionsContext);

    if (!userActionsContext) {
        throw new Error(
            "useUserActionsContext has to be used within <UserActionsContext.Provider>"
        );
    }
    return userActionsContext;
};